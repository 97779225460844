import qs from 'qs';
import { MAP_MOSCOW_CENTER, MAP_MOSCOW_ZOOM } from '../constants';

export default function mapLocationQueryToQuery(raw) {
  const defaults = { center: MAP_MOSCOW_CENTER, zoom: MAP_MOSCOW_ZOOM };
  const overrides = {};

  // prettier-ignore
  const query = typeof raw === 'string'
    ? qs.parse(raw, { ignoreQueryPrefix: true }) : raw;

  if (query.priceFrom) {
    overrides.priceFrom = parseInt(query.priceFrom, 10);
  }

  if (query.priceTo) {
    overrides.priceTo = parseInt(query.priceTo, 10);
  }

  if (query.rooms) {
    overrides.rooms = query.rooms.map(value => parseInt(value, 10));
  }

  if (query.zoom) {
    overrides.zoom = parseFloat(query.zoom);
  }

  if (query.center) {
    overrides.center = {
      lat: parseFloat(query.center.lat),
      lng: parseFloat(query.center.lng)
    };
  }

  if (query.bounds) {
    overrides.bounds = [
      // prettier-ignore
      { lat: parseFloat(query.bounds[0].lat), lng: parseFloat(query.bounds[0].lng) },
      // prettier-ignore
      { lat: parseFloat(query.bounds[1].lat), lng: parseFloat(query.bounds[1].lng) }
    ];
  }

  if (query.city) {
    overrides.city = parseInt(query.city, 10);
  }

  // override requested page for the data list
  overrides.page = (query.page && parseInt(query.page, 10)) || 0;

  return { ...defaults, ...overrides };
}
