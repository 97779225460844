import { Picture } from 'pik-arenda-ui-kit';

function revealString(value, placeholder = false) {
  return typeof value === 'string' && value ? value : placeholder;
}

export default function mapFlatData(data) {
  const priceParsed = parseInt(data.price, 10);
  const depositParsed = parseInt(data.deposit, 10);

  // the store commission in percents, but they store deposit in currency 🤷‍
  const commissionPercentsParsed = parseInt(data.comission, 10);
  const commissionParsed = Math.ceil((priceParsed / 100) * commissionPercentsParsed);

  const flat = {
    id: String(data.id),
    bookingPhone: revealString(data.phone),
    flatRooms: parseInt(data.rooms, 10),
    flatSquare: parseFloat(data.area_total),
    flatPrice: priceParsed,
    flatGallery: null,
    flatThumbs: null,
    flatPhotos: null,
    flatDeposit: depositParsed,
    flatCommission: commissionParsed,
    flatHasInsurance: !!parseInt(data.hasInsurance, 10),
    flatFloor: parseInt(data.floor, 10),
    houseLng: parseFloat(data.longitude),
    houseLat: parseFloat(data.latitude),
    houseFloorsCount: parseInt(data.floor_count, 10),
    houseStationLabel: (data.metro && data.metro.name) || false,
    houseStationWalk: data.metro && data.metro.walk ? parseInt(data.metro.walk, 10) : false,
    houseStationDrive: data.metro && data.metro.drive ? parseInt(data.metro.drive, 10) : false,
    fullAddress: String(data.address),
    publishedDate: String(data.published),
    livingspace: parseFloat(data.area_living),
    kitchenspace: parseFloat(data.area_kitchen),
    scheme: data.plan && data.plan,
    schemeThumb: data.plan && Picture.getUriToOptimizedPicture(data.plan, 400, 200),
    description: String(data.description),
    maycats: !!parseInt(data.maycats, 10),
    maychilds: !!parseInt(data.maychilds, 10),
    maydogs: !!parseInt(data.maydogs, 10),
    maysmoke: !!parseInt(data.maysmoke, 10),
    hasbathtub: !!parseInt(data.hasbathtub, 10),
    hasconditioner: !!parseInt(data.hasconditioner, 10),
    hasphone: !!parseInt(data.hasphone, 10),
    hasinternet: !!parseInt(data.hasinternet, 10),
    haswifi: !!parseInt(data.haswifi, 10),
    hastvbox: !!parseInt(data.hastvbox, 10),
    hastv: !!parseInt(data.hastv, 10),
    hasfurniture: !!parseInt(data.hasfurniture, 10),
    haskitchenfurniture: !!parseInt(data.haskitchenfurniture, 10),
    hasfurnace: !!parseInt(data.hasfurnace, 10),
    hasmicrowave: !!parseInt(data.hasmicrowave, 10),
    haswasher: !!parseInt(data.haswasher, 10),
    hasdishwasher: !!parseInt(data.hasdishwasher, 10),
    hasshower: !!parseInt(data.hasshower, 10),
    hasfridge: !!parseInt(data.hasfridge, 10),
    hasiron: !!parseInt(data.hasiron, 10),
    haslift: !!parseInt(data.haslift, 10),
    hasparking: !!parseInt(data.hasparking, 10),
    status: data.status === 100 || data.status === 101,
    utilitiesIncluded: parseInt(data.utilities_included, 10),
    utilitiesValue: parseInt(data.utilities_value, 10)
  };

  flat.flatGallery = flat.scheme ? data.photos.filter(src => src !== String(flat.scheme)) : data.photos;

  flat.flatGalleryThumbs = flat.flatGallery;

  return flat;
}
