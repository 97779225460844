const unresolvedErrorMessage = 'Unresolved error happened: please, see the browser console';

function resolveErrorMessage(error) {
  let status;
  let message;

  if (error.response) {
    const { response } = error;
    const { data, headers } = response;
    status = response.status;

    if (status === 404) {
      message = `Requested endpoint (${response.config.url}) not found`;
    } else if (typeof data === 'string') {
      const isHtmlResponse = headers['content-type'] && headers['content-type'].indexOf('text/html') === 0;
      if (!isHtmlResponse) {
        message = data;
      }
    } else if (typeof data === 'object') {
      message = data.message;
    }
  } else {
    status = 500;
    message = error.message;
  }

  return { status, message: message || unresolvedErrorMessage };
}

export default function apiHandleError(error) {
  const isSilent = error.config.params && error.config.params.silent;
  if (!isSilent) {
    console.log(error);
    const { status, message } = resolveErrorMessage(error);
    alert(`[${status}] ${message}`);
  }
}
