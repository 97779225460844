import sortSlotsByTimestamp from './sortSlotsByTimestamp';
import filterSlotsByBookingDelay from './filterSlotsByBookingDelay';
import filterSlotsByWorkingPeriod from './filterSlotsByWorkingPeriod';
import filterSlotsByAvailabilityGaps from './filterSlotsByAvailabilityGaps';
import filterSlotsByHorizonPeriod from './filterSlotsByHorizonPeriod';

export default function resolveSlotsPrepared(slotsRaw) {
  return sortSlotsByTimestamp(
    filterSlotsByBookingDelay(
      filterSlotsByWorkingPeriod(filterSlotsByAvailabilityGaps(filterSlotsByHorizonPeriod(slotsRaw)))
    )
  );
}
