import { isEnvironmentPlatformBrowser } from 'pik-arenda-ui-kit/helpers';

export default function documentScrollAction() {
  if (isEnvironmentPlatformBrowser()) {
    window.document.documentElement.style.overflowY = 'scroll';
  }

  return {
    destroy: () => {
      if (isEnvironmentPlatformBrowser()) {
        window.document.documentElement.style.overflowY = '';
      }
    }
  };
}
