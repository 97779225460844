import moment from 'moment';
import { DAYS_HORIZON, LOCATION_TIMEZONE_UTC_OFFSET } from '../constants';

export default function resolveBookingInterval() {
  const beginDate = new Date();
  const endDate = new Date();

  beginDate.setHours(0);
  beginDate.setMinutes(0);
  beginDate.setSeconds(0);

  endDate.setDate(endDate.getDate() + DAYS_HORIZON);
  endDate.setHours(23);
  endDate.setMinutes(30);
  endDate.setSeconds(0);

  const from = moment(beginDate);
  const to = moment(endDate);

  // TODO Support timezones with current flat timezone: currently moscow timezone used.
  // change timezone offset to flat location offset
  from.utcOffset(LOCATION_TIMEZONE_UTC_OFFSET);
  to.utcOffset(LOCATION_TIMEZONE_UTC_OFFSET);

  return { from, to };
}
