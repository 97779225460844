export const parseAllSearchParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = {};

  for (const [key, value] of urlSearchParams.entries()) {
    searchParams[key] = value;
  }

  return searchParams;
};
