import { isEnvironmentStageMesosphere } from 'pik-arenda-ui-kit/helpers';

// app components flags
export const SENTRY_ENABLED = isEnvironmentStageMesosphere();
// Identity helper (visitor service) temporarily unavailable
// TODO Replace "true" instead of "false" when the service is available
export const IDENTITY_ENABLED =
  isEnvironmentStageMesosphere() &&
  (process.env.IS_ENABLED_VISITOR_SERVICE !== undefined ? JSON.parse(process.env.IS_ENABLED_VISITOR_SERVICE) : false);
export const METRIC_ENABLED = isEnvironmentStageMesosphere();
export const COMAGIC_ENABLED = isEnvironmentStageMesosphere();
export const EVENTS_ENABLED = isEnvironmentStageMesosphere();

export const SENTRY_DSN = 'https://0ef2ead2dfc64a059263788615f288db@sentry.io/1855056';

export const URI_SEARCH = '/';
export const URI_DETAIL = '/:id/';

// TODO Ask the backend team to setup test environment and switch to test for the booking api.
export const BASE_URI_API = isEnvironmentStageMesosphere()
  ? 'https://vitrina.pik-arenda.ru/api'
  : 'https://vitrina.pik-arenda.ru/api';

export const BASE_URI_OPTIMIZER_API = isEnvironmentStageMesosphere()
  ? 'https://optimizer.p-a.im/optimize'
  : 'https://optimizer.pik-arenda.fronttest.p-a.im/optimize';

export const REDIRECT_404_URL = isEnvironmentStageMesosphere()
  ? 'https://pik-arenda.ru/404'
  : 'https://pik-arenda.fronttest.p-a.im/404/';

export const API_PARAM_TEST = !isEnvironmentStageMesosphere();

export const API_URI_SEARCH_FLATS = '/flats/';
export const API_URI_SEARCH_POINTS = '/flats_dots/';
export const API_URI_FLAT_READ = '/flat/?uuid=:id';
export const API_URI_FLAT_SLOT_INDEX = '/slots/';
export const API_URI_FLAT_BOOKING_CREATE = '/appointment/';
export const API_URI_FLAT_BOOKING_INDEX = '/appointments_list/';
export const API_URI_FLAT_BOOKING_DELETE = '/appointment_cancel/';

export const MAP_ZOOM_PRICE_THRESHOLD = 13;
export const MAP_INSIDE_STATION_THRESHOLD = 0.004; // value to change lat and lng
export const MAP_MOSCOW_CENTER = { lng: 37.64, lat: 55.76 };
export const MAP_MOSCOW_ZOOM = 10;
export const MAP_MOSCOW_ZOOM_STATION = 13;
export const MAP_MOSCOW_BOUNDS = [
  { lng: 37.096, lat: 55.612 },
  { lng: 38.151, lat: 55.906 }
];
export const MAP_SPB_CENTER = { lng: 30.32, lat: 59.93 };
export const MAP_SPB_ZOOM = 10;
export const MAP_SPB_ZOOM_STATION = 13;
export const MAP_SPB_BOUNDS = [
  { lng: 29.783, lat: 59.635 },
  { lng: 30.856, lat: 60.222 }
];
export const MAP_VRNZH_CENTER = { lng: 39.19, lat: 51.66 };
export const MAP_VRNZH_ZOOM = 11;
export const MAP_VRNZH_BOUNDS = [
  { lng: 38.921, lat: 51.477 },
  { lng: 39.458, lat: 51.841 }
];
export const MAP_KRR_CENTER = { lng: 38.97, lat: 45.03 };
export const MAP_KRR_ZOOM = 12;
export const MAP_KRR_BOUNDS = [
  { lng: 38.835, lat: 44.926 },
  { lng: 39.104, lat: 45.133 }
];
export const MAP_RND_CENTER = { lng: 39.72, lat: 47.22 };
export const MAP_RND_ZOOM = 11;
export const MAP_RND_BOUNDS = [
  { lng: 39.451, lat: 47.02 },
  { lng: 39.988, lat: 47.418 }
];
export const MAP_CHEL_CENTER = { lng: 61.4, lat: 55.16 };
export const MAP_CHEL_ZOOM = 10;
export const MAP_CHEL_BOUNDS = [
  { lng: 60.863, lat: 54.823 },
  { lng: 61.936, lat: 55.493 }
];
export const MAP_EKB_CENTER = { lng: 60.69, lat: 56.8 };
export const MAP_EKB_ZOOM = 11;
export const MAP_EKB_ZOOM_STATION = 13;
export const MAP_EKB_BOUNDS = [
  { lng: 60.421, lat: 56.639 },
  { lng: 60.958, lat: 56.96 }
];
export const MAP_UFA_CENTER = { lng: 56, lat: 54.7 };
export const MAP_UFA_ZOOM = 12;
export const MAP_UFA_BOUNDS = [
  { lng: 55.865, lat: 54.615 },
  { lng: 56.134, lat: 54.784 }
];
export const MAP_SAM_CENTER = { lng: 50.15, lat: 53.2 };
export const MAP_SAM_ZOOM = 12;
export const MAP_SAM_BOUNDS = [
  { lng: 50.015, lat: 53.112 },
  { lng: 50.284, lat: 53.287 }
];
export const MAP_NNVG_CENTER = { lng: 44, lat: 56.33 };
export const MAP_NNVG_ZOOM = 11;
export const MAP_NNVG_BOUNDS = [
  { lng: 43.491, lat: 56.165 },
  { lng: 44.507, lat: 56.48 }
];

export const FLAT_SIMILAR_LIMIT = 4;

export const FLATS_INFINITE_SCROLL_THRESHOLD = 500;

export const STORAGE_KEY_PREFIX = 'pik-arenda';
export const STORAGE_KEY_VISITED_FLATS = `${STORAGE_KEY_PREFIX}_visited-flats`;
export const STORAGE_KEY_BOOKING_TOKEN = `${STORAGE_KEY_PREFIX}_booking-token`;

export const SEARCH_QUERY_PARAM_CENTER = 'center';
export const SEARCH_QUERY_PARAM_ZOOM = 'zoom';
export const SEARCH_QUERY_PARAM_PRICE_FROM = 'priceFrom';
export const SEARCH_QUERY_PARAM_PRICE_TO = 'priceTo';
export const SEARCH_QUERY_PARAM_ROOMS = 'rooms';

export const MOMENT_LOCALE = 'ru';

export const HTTP_STATUS_UNAUTHORIZED = 401;

export const SERVER_SIDE_WINDOW_WIDTH = 1200;

// See in db or ask backend team what the city innerIndex is
// The order of city important - it is the order in select from vitrina
export const SUPPORTED_REGIONS = [
  {
    name: 'Москва',
    where: 'Москве',
    index: 77,
    style: 'red',
    innerIndex: 1,
    center: MAP_MOSCOW_CENTER,
    bounds: MAP_MOSCOW_BOUNDS,
    zoom: MAP_MOSCOW_ZOOM,
    subwayZoom: MAP_MOSCOW_ZOOM_STATION,
    coverTheme: 'msc'
  },
  {
    name: 'Санкт-Петербург',
    where: 'Санкт-Петербурге',
    index: 78,
    style: 'blue',
    innerIndex: 2,
    center: MAP_SPB_CENTER,
    bounds: MAP_SPB_BOUNDS,
    zoom: MAP_SPB_ZOOM,
    subwayZoom: MAP_SPB_ZOOM_STATION,
    coverTheme: 'spb'
  },
  {
    name: 'Воронеж',
    where: 'Воронеже',
    index: 36,
    style: 'blue',
    innerIndex: 4,
    center: MAP_VRNZH_CENTER,
    bounds: MAP_VRNZH_BOUNDS,
    zoom: MAP_VRNZH_ZOOM,
    coverTheme: 'vrnz'
  },
  {
    name: 'Екатеринбург',
    where: 'Екатеринбурге',
    index: 66,
    style: 'blue',
    innerIndex: 10,
    center: MAP_EKB_CENTER,
    bounds: MAP_EKB_BOUNDS,
    zoom: MAP_EKB_ZOOM,
    subwayZoom: MAP_EKB_ZOOM_STATION,
    coverTheme: 'ekb'
  },
  {
    name: 'Краснодар',
    where: 'Краснодаре',
    index: 23,
    style: 'blue',
    innerIndex: 8,
    center: MAP_KRR_CENTER,
    bounds: MAP_KRR_BOUNDS,
    zoom: MAP_KRR_ZOOM,
    coverTheme: 'krr'
  },
  {
    name: 'Нижний Новгород',
    where: 'Нижнем Новгороде',
    index: 52,
    style: 'blue',
    innerIndex: 3,
    center: MAP_NNVG_CENTER,
    bounds: MAP_NNVG_BOUNDS,
    zoom: MAP_NNVG_ZOOM,
    coverTheme: 'nnvg'
  },
  {
    name: 'Ростов-на-Дону',
    where: 'Ростове-на-Дону',
    index: 61,
    style: 'blue',
    innerIndex: 6,
    center: MAP_RND_CENTER,
    bounds: MAP_RND_BOUNDS,
    zoom: MAP_RND_ZOOM,
    coverTheme: 'rnd'
  },
  // {
  //   name: 'Уфа',
  //   where: 'Уфе',
  //   index: 2,
  //   style: 'blue',
  //   innerIndex: 12,
  //   center: MAP_UFA_CENTER,
  //   bounds: MAP_UFA_BOUNDS,
  //   zoom: MAP_UFA_ZOOM,
  //   coverTheme: 'ufa'
  // },
  {
    name: 'Челябинск',
    where: 'Челябинске',
    index: 74,
    style: 'blue',
    innerIndex: 9,
    center: MAP_CHEL_CENTER,
    bounds: MAP_CHEL_BOUNDS,
    zoom: MAP_CHEL_ZOOM,
    coverTheme: 'chel'
  }
];

export const MAP_DELTA = 0.2;
