import { FlatHelper } from 'pik-arenda-ui-kit/core';
import resolveTitle from '../../../helpers/resolveTitle';

export default function resolveDetailPageTitle(flat = {}) {
  return resolveTitle(
    FlatHelper.title({
      square: flat.flatSquare,
      rooms: flat.flatRooms,
      floor: flat.flatFloor,
      floors: flat.houseFloorsCount
    })
  );
}
