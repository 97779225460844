import FlatBookingSelectStep from './steps/FlatBookingSelectStep';
import FlatBookingPhoneStep from './steps/FlatBookingPhoneStep';
import FlatBookingConfirmStep from './steps/FlatBookingConfirmStep';
import FlatBookingResultStep from './steps/FlatBookingResultStep';
import FlatBookingCallStep from './steps/FlatBookingCallStep';
import { STEP_SELECT, STEP_PHONE, STEP_CONFIRM, STEP_RESULT, STEP_CALL } from './constants';

export default {
  [STEP_SELECT]: FlatBookingSelectStep,
  [STEP_PHONE]: FlatBookingPhoneStep,
  [STEP_CONFIRM]: FlatBookingConfirmStep,
  [STEP_RESULT]: FlatBookingResultStep,
  [STEP_CALL]: FlatBookingCallStep
};
