<script>
  import { createEventDispatcher } from 'svelte';
  import { HeaderWithMenuDefault } from 'pik-arenda-ui-kit';

  const dispatch = createEventDispatcher();

  export let isModalVisible = false;
  export let isMobileListActive = false;
  export let isMobileMapActive = false;
  export let theme;
  export let menuOptionTheme;
  export let withShadow = true;
  export let mode = 'wide';
  export let lkIconTheme;
  export let cityDetectorEnabled = false;

  const modalWithBtnText = 'Сдать квартиру';
  const modalWithBtnMobileText = 'Сдать';
  const withFilters = true;

  function onToggleFilter(e) {
    e.preventDefault();
    dispatch('filter');
  }

  function onShowMapView(e) {
    e.preventDefault();
    dispatch('toggle', { view: 'map' });
  }

  function onShowListView(e) {
    e.preventDefault();
    dispatch('toggle', { view: 'list' });
  }
</script>

<style src="./styles.pcss" lang="pcss">.content-mobile,.controls,.controls__list{width:100%;height:100%}.controls__list{display:flex;align-items:center;flex-direction:row;justify-content:center;padding:0 16px}.controls__item{font-size:12px;line-height:14px;color:#26334d;font-weight:600;margin-right:16px}@media (min-width:576px){.controls__item{font-size:14px}}.controls__item:last-child{margin-right:0}.controls__item:hover{cursor:pointer}.controls__name-item{margin-right:auto}.inactive{color:#c8ccd2}.content-filters{width:100%;height:100%}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9IZWFkZXJXaXRoRmlsdGVyc0ZlYXR1cmUvSGVhZGVyV2l0aEZpbHRlcnNGZWF0dXJlLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFTRSwwQ0FIQSxVQUFXLENBQ1gsV0FVQSxDQVJBLGdCQUdFLFlBQWEsQ0FDYixrQkFBbUIsQ0FDbkIsa0JBQW1CLENBQ25CLHNCQUF1QixDQUN2QixjQUNGLENBRUEsZ0JBQ0UsY0FBZSxDQUNmLGdCQUFpQixDQUNqQixhQUFjLENBQ2QsZUFBZ0IsQ0FDaEIsaUJBYUYsQ0FYRSx5QkFQRixnQkFRSSxjQVVKLENBVEUsQ0FFQSwyQkFDRSxjQUNGLENBRUEsc0JBQ0UsY0FDRixDQUdGLHFCQUNFLGlCQUNGLENBTUYsVUFDRSxhQUNGLENBRUEsaUJBQ0UsVUFBVyxDQUNYLFdBQ0YiLCJmaWxlIjoic3JjL2ZlYXR1cmVzL0hlYWRlcldpdGhGaWx0ZXJzRmVhdHVyZS9IZWFkZXJXaXRoRmlsdGVyc0ZlYXR1cmUuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRlbnQtbW9iaWxlIHtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcbn1cblxuLmNvbnRyb2xzIHtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcblxuICAmX19saXN0IHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGZsZXgtZGlyZWN0aW9uOiByb3c7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgcGFkZGluZzogMCAxNnB4O1xuICB9XG5cbiAgJl9faXRlbSB7XG4gICAgZm9udC1zaXplOiAxMnB4O1xuICAgIGxpbmUtaGVpZ2h0OiAxNHB4O1xuICAgIGNvbG9yOiAjMjYzMzRkO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG4gICAgbWFyZ2luLXJpZ2h0OiAxNnB4O1xuXG4gICAgQG1lZGlhIChtaW4td2lkdGg6IDU3NnB4KSB7XG4gICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgfVxuXG4gICAgJjpsYXN0LWNoaWxkIHtcbiAgICAgIG1hcmdpbi1yaWdodDogMDtcbiAgICB9XG5cbiAgICAmOmhvdmVyIHtcbiAgICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICB9XG4gIH1cblxuICAmX19uYW1lLWl0ZW0ge1xuICAgIG1hcmdpbi1yaWdodDogYXV0bztcbiAgfVxufVxuXG4uYWN0aXZlIHtcbn1cblxuLmluYWN0aXZlIHtcbiAgY29sb3I6ICNjOGNjZDI7XG59XG5cbi5jb250ZW50LWZpbHRlcnMge1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDAlO1xufVxuIl19 */</style>

<HeaderWithMenuDefault
  {withShadow}
  {withFilters}
  cabinetEnabled
  bind:isModalVisible
  modalWithBtnEnabled
  {modalWithBtnText}
  {modalWithBtnMobileText}
  {theme}
  {menuOptionTheme}
  {mode}
  {lkIconTheme}
  {cityDetectorEnabled}>
  <div slot="content-filters" class="content-filters">
    <div class="controls">
      <ul class="controls__list">
        <li class="controls__item controls__name-item" on:click={onToggleFilter}>Фильтры</li>
        <li class="controls__item {isMobileListActive ? 'active' : 'inactive'}" on:click={onShowMapView}>На карте</li>
        <li class="controls__item {isMobileMapActive ? 'active' : 'inactive'}" on:click={onShowListView}>Списком</li>
      </ul>
    </div>
  </div>
</HeaderWithMenuDefault>
