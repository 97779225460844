export default function axiosListenToErrors(instance, handler) {
  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      handler(error);
      throw error;
    }
  );
}
