import moment from 'moment';
import { FORMAT_DATE } from '../constants';

export default function resolveDatesOptions(slots) {
  if (!slots) {
    return [];
  }

  return slots.map(slot => ({
    value: slot.date,
    label: moment(slot.date, FORMAT_DATE).format('D MMMM')
  }));
}
