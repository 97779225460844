<script>
  import { onMount } from 'svelte';
  import { DebugHelper, SentryHelper, SingleSignHelper } from 'pik-arenda-ui-kit/core';
  import { Button, ShowCaseDetailBookingWrapper, Grid } from 'pik-arenda-ui-kit';
  import resolveSlotsHierarchy from './helpers/resolveSlotsHierarchy';
  import resolveAssignedBooking from './helpers/resolveAssignedBooking';
  import resolveSlotsPrepared from './helpers/resolveSlotsPrepared';
  import isBookingOutdated from './helpers/isBookingOutdated';
  import { fetchSlots, fetchBookings } from './instances/interface';
  import steps from './steps';
  import { stepStore, dateStore, timeStore, bookingStore } from './stores';
  import { HTTP_STATUS_UNAUTHORIZED } from './../../constants';
  import { STEP_RESULT, STEP_CALL, STEPS_IGNORE_GREETING } from './constants';
  import eyes from './images/eyes.png';

  export let id;
  export let flat;
  export let slots = [];
  export let ready = false;
  export let greeting = false;
  export let booked = false;
  export let loading = false;
  export let checked = false;
  export let loaded = false;
  export let mode = undefined;
  export let shake;

  let windowWidth;

  onMount(onEnsure);

  async function load() {
    try {
      const slotsRaw = await fetchSlots(id);
      const slotsPrepared = resolveSlotsPrepared(slotsRaw);
      slots = resolveSlotsHierarchy(slotsPrepared);
    } catch (error) {
      SentryHelper.capture(error);
    }

    // receive user orders for the flat
    if (SingleSignHelper.token) {
      try {
        const bookings = await fetchBookings(id, SingleSignHelper.token);
        const booking = resolveAssignedBooking(bookings);
        bookingStore.set(booking);

        if (booking && !isBookingOutdated(booking)) {
          booked = true;
          // eslint-disable-next-line require-atomic-updates
          dateStore.set(booking.date);
          // eslint-disable-next-line require-atomic-updates
          timeStore.set(booking.time);
          stepStore.set(STEP_RESULT);
        }
      } catch (error) {
        if (error.response && error.response.status) {
          if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
            SingleSignHelper.reset();
          }
        }

        SentryHelper.capture(error);
      }
    }

    // set initial slot value after the whole process of loading
    // we do not want to blink screen
    if (slots.length && !$dateStore && !$timeStore) {
      dateStore.set(slots[0].date);
      timeStore.set(slots[0].times[0]);
    }
  }

  async function onEnsure() {
    if (isBookingViaPhone) {
      // toggle step form into call step
      stepStore.set(STEP_CALL);
    }
  }

  async function onBegin(e) {
    e.preventDefault();

    if (!ready || checked) {
      greeting = false;
      return;
    }

    checked = true;
    if (!isBookingViaPhone) {
      loading = true;

      // dynamically load booking slots
      // do not load booking slots on server side rendering
      // if you want to keep data actual
      await load();
      loading = false;
    }

    greeting = false;
  }

  $: isBookingViaPhone = flat && flat.bookingPhone && !DebugHelper.check('force-record-through-form');
</script>

<style src="./styles.pcss" lang="pcss">.greeting{margin-top:18px}.rented-message{padding:16px;background:#fff7e8;position:relative;box-shadow:0 2.5px 8px rgba(0,0,0,.15);margin-top:-5px;border-radius:0}@media (min-width:768px){.rented-message{border-radius:0 0 5px 5px}}.rented-message .rented-message__header{font-size:20px;font-weight:600}@media (min-width:768px){.rented-message .rented-message__header{font-size:30px}}.rented-message .rented-message__container{display:flex;margin-top:10px;margin-bottom:10px;align-items:center}.rented-message .rented-message__container .rented-message__img{width:40px;height:40px;margin-right:13px}.rented-message .rented-message__container .rented-message__container{color:#5e6366;font-size:12px;line-height:14px}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9GbGF0Qm9va2luZ0ZlYXR1cmUvRmxhdEJvb2tpbmdGZWF0dXJlLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxVQUNFLGVBQ0YsQ0FDQSxnQkFDRSxZQUFhLENBQ2Isa0JBQW1CLENBQ25CLGlCQUFrQixDQUNsQixzQ0FBd0MsQ0FDeEMsZUFBZ0IsQ0FDaEIsZUFpQ0YsQ0EvQkUseUJBUkYsZ0JBU0kseUJBOEJKLENBN0JFLENBRUEsd0NBQ0UsY0FBZSxDQUNmLGVBS0YsQ0FIRSx5QkFKRix3Q0FLSSxjQUVKLENBREUsQ0FHRiwyQ0FDRSxZQUFhLENBQ2IsZUFBZ0IsQ0FDaEIsa0JBQW1CLENBQ25CLGtCQWFGLENBWEUsZ0VBQ0UsVUFBVyxDQUNYLFdBQVksQ0FDWixpQkFDRixDQUVBLHNFQUNFLGFBQWMsQ0FDZCxjQUFlLENBQ2YsZ0JBQ0YiLCJmaWxlIjoic3JjL2ZlYXR1cmVzL0ZsYXRCb29raW5nRmVhdHVyZS9GbGF0Qm9va2luZ0ZlYXR1cmUuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLmdyZWV0aW5nIHtcbiAgbWFyZ2luLXRvcDogMThweDtcbn1cbi5yZW50ZWQtbWVzc2FnZSB7XG4gIHBhZGRpbmc6IDE2cHg7XG4gIGJhY2tncm91bmQ6ICNmZmY3ZTg7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgYm94LXNoYWRvdzogMCAyLjVweCA4cHggcmdiKDAgMCAwIC8gMTUlKTtcbiAgbWFyZ2luLXRvcDogLTVweDtcbiAgYm9yZGVyLXJhZGl1czogMHB4O1xuXG4gIEBtZWRpYSAoLS1tZCkge1xuICAgIGJvcmRlci1yYWRpdXM6IDBweCAwcHggNXB4IDVweDtcbiAgfVxuXG4gICYgLnJlbnRlZC1tZXNzYWdlX19oZWFkZXIge1xuICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgICBmb250LXdlaWdodDogNjAwO1xuXG4gICAgQG1lZGlhICgtLW1kKSB7XG4gICAgICBmb250LXNpemU6IDMwcHg7XG4gICAgfVxuICB9XG5cbiAgJiAucmVudGVkLW1lc3NhZ2VfX2NvbnRhaW5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBtYXJnaW4tdG9wOiAxMHB4O1xuICAgIG1hcmdpbi1ib3R0b206IDEwcHg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICAgICYgLnJlbnRlZC1tZXNzYWdlX19pbWcge1xuICAgICAgd2lkdGg6IDQwcHg7XG4gICAgICBoZWlnaHQ6IDQwcHg7XG4gICAgICBtYXJnaW4tcmlnaHQ6IDEzcHg7XG4gICAgfVxuXG4gICAgJiAucmVudGVkLW1lc3NhZ2VfX2NvbnRhaW5lciB7XG4gICAgICBjb2xvcjogIzVlNjM2NjtcbiAgICAgIGZvbnQtc2l6ZTogMTJweDtcbiAgICAgIGxpbmUtaGVpZ2h0OiAxNHB4O1xuICAgIH1cbiAgfVxufVxuIl19 */</style>

<svelte:window bind:innerWidth={windowWidth} />

<ShowCaseDetailBookingWrapper isRented={flat.status} {flat} {id} {mode} bind:shake>
  {#if !flat.status}
    {#if !greeting || STEPS_IGNORE_GREETING.includes($stepStore)}
      <svelte:component this={steps[$stepStore]} bind:id bind:flat bind:slots bind:loading bind:mode />
    {:else}
      <div class="greeting">
        {#if windowWidth >= Grid.VIEWPORT_THRESHOLD_SM && windowWidth <= Grid.VIEWPORT_THRESHOLD_MD}
          <Button block on:click={onBegin} {loading} size="m">
            {#if $bookingStore}Вы записались на просмотр{:else}Записаться на просмотр{/if}
          </Button>
        {:else}
          <Button block on:click={onBegin} {loading}>
            {#if $bookingStore}Вы записались на просмотр{:else}Записаться на просмотр{/if}
          </Button>
        {/if}
      </div>
    {/if}
  {/if}
</ShowCaseDetailBookingWrapper>
{#if flat.status}
  <div class="rented-message">
    <div class="rented-message__header">Квартира сдана</div>
    <div class="rented-message__container">
      <img class="rented-message__img" src={eyes} alt="eyes" />
      <div class="rented-message__container">Но вы всегда можете посмотреть другие квартиры на нашей витрине</div>
    </div>
    <Button block href="./" size="m">Смотреть другие квартиры</Button>
  </div>
{/if}
