const getCookieDomainLastTwoParts = () => {
  const hostnameParts = window.location.hostname.split('.');
  return hostnameParts.slice(hostnameParts.length - 2).join('.');
};

export const setCookie = (name, value, { unlimited = false } = {}) => {
  const nameValueEncoded = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  const domain = ';domain=' + getCookieDomainLastTwoParts();
  let expires = '';

  if (unlimited === true) {
    let date = new Date(Date.now() + 2 * 365 * 24 * 60 * 60 * 1000);
    date = date.toUTCString();
    expires = ';expires=' + date;
  }

  document.cookie = nameValueEncoded + expires + domain + ';path=/';
};

export const parseCookiesToObject = () => {
  const cookies = document.cookie.split('; ');
  const cookieObject = {};

  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    cookieObject[key] = decodeURIComponent(value);
  }

  return cookieObject;
};
