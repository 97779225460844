import moment from 'moment';
import api from '../../../instances/api';
import resolveBookingInterval from '../helpers/resolveBookingInterval';
import {
  API_URI_FLAT_SLOT_INDEX,
  API_URI_FLAT_BOOKING_INDEX,
  API_URI_FLAT_BOOKING_CREATE,
  API_URI_FLAT_BOOKING_DELETE
} from '../../../constants';
import { SentryHelper, IdentityHelper } from 'pik-arenda-ui-kit/core';
import { FORMAT_DATE, FORMAT_TIME } from '../constants';
import { utcStore } from '../stores';

let utcOffset;

utcStore.subscribe(value => {
  utcOffset = value;
});

export async function fetchSlots(id) {
  // resolve booking intervals in current flat timezone
  const { from, to } = resolveBookingInterval();

  // send request to search for available slots
  const params = { uuid: id, date: from.format('YYYY-MM-DD'), dateto: to.format('YYYY-MM-DD') };
  return api
    .get(API_URI_FLAT_SLOT_INDEX, { params })
    .then(response => {
      return response.data && typeof response.data === 'object' && response.data instanceof Array ? response.data : [];
    })
    .catch(error => {
      SentryHelper.capture(error);
      return [];
    });
}

export async function fetchBookings(id, token) {
  const requestConfig = { params: { token, uuid: id, silent: true } };
  const response = await api.get(API_URI_FLAT_BOOKING_INDEX, requestConfig);
  return response.data && typeof response.data === 'object' && response.data instanceof Array
    ? Object.values(response.data)
    : [];
}

export async function sendBooking(id, token, dateOriginal, timeOriginal) {
  const timestamp =
    utcOffset !== 180
      ? moment(`${dateOriginal} ${timeOriginal}`, `${FORMAT_DATE} ${FORMAT_TIME}`).subtract(
          (utcOffset - 180) / 60,
          'hours'
        )
      : moment(`${dateOriginal} ${timeOriginal}`, `${FORMAT_DATE} ${FORMAT_TIME}`);

  const uid = await IdentityHelper.resolvePikId();
  const date = timestamp.format('YYYY-MM-DD');
  const time = timestamp.format('HH:mm');

  const config = { params: { token, uuid: id, date, time, silent: true, pik_uid: uid } };

  return api.post(API_URI_FLAT_BOOKING_CREATE, { pik_uid: uid }, config).then(response => {
    return {
      id: parseInt(response.data.deal_id, 10),
      date: dateOriginal,
      time: timeOriginal,
      code: response.data.code
    };
  });
}

export async function cancelBooking(id, token, deal) {
  const uid = await IdentityHelper.resolvePikId();
  const params = { uuid: id, token, deal_id: deal, pik_uid: uid };
  return api.post(API_URI_FLAT_BOOKING_DELETE, { pik_uid: uid }, { params });
}
