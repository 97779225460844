import { FlatHelper } from 'pik-arenda-ui-kit/core';
import resolveTitle from '../../../helpers/resolveTitle';

export default function resolveSearchPageTitle(query = {}) {
  return resolveTitle(
    FlatHelper.titleSearch({
      from: query.priceFrom,
      to: query.priceTo,
      rooms: query.rooms
    })
  );
}
