import moment from 'moment';
import { FORMAT_TIME, FORMAT_DATE, BOOKING_AVAILABILITY_DELAY, WORKING_DAY_FROM, WORKING_DAY_TILL } from '../constants';
import { utcStore } from '../stores';

let utcOffset;

utcStore.subscribe(value => {
  utcOffset = value;
});

export default function filterSlotsByBookingDelay(raw) {
  const current = moment().utcOffset(utcOffset);
  const evening = moment(current.format('YYYY-MM-DD') + WORKING_DAY_TILL, FORMAT_DATE + FORMAT_TIME).utcOffset(
    utcOffset,
    true
  );
  const morning = moment(current.format('YYYY-MM-DD') + WORKING_DAY_FROM, FORMAT_DATE + FORMAT_TIME).utcOffset(
    utcOffset,
    true
  );
  const tomorrow = moment(current.format('YYYY-MM-DD') + WORKING_DAY_FROM, FORMAT_DATE + FORMAT_TIME).utcOffset(
    utcOffset,
    true
  );
  tomorrow.add(1, 'd');

  let threshold = moment.unix(current.unix() + BOOKING_AVAILABILITY_DELAY).utcOffset(utcOffset);

  const isSameOrAfterEvening = threshold.isSameOrAfter(evening, 'minute');
  const isSameOrBeforeMorning = threshold.isSameOrBefore(morning, 'minute');

  if (isSameOrAfterEvening || isSameOrBeforeMorning) {
    // calculate difference point regarding time of the day:
    // is it morning or is it evening?
    let differencePoint;
    let differenceMorningPoint;
    if (isSameOrAfterEvening) {
      differencePoint = evening.clone();
      differenceMorningPoint = tomorrow.clone();
    } else if (isSameOrBeforeMorning) {
      differencePoint = evening.clone();
      differencePoint.subtract(1, 'd');
      differenceMorningPoint = morning.clone();
    }

    const difference = differencePoint.unix() - current.unix();
    const differenceFormatted = difference < 0 ? 0 : difference;
    const offset = BOOKING_AVAILABILITY_DELAY - differenceFormatted;
    threshold = moment.unix(differenceMorningPoint.unix() + offset).utcOffset(utcOffset);
  }

  // remove one second because we want to get
  // something like '10:59:59' to valid compare
  threshold.subtract(1, 'second');

  return raw.filter(datetime => {
    const timestamp = moment.parseZone(datetime);

    // TODO Calculate timezone offset.
    return timestamp.isAfter(threshold, 'minute');
  });
}
