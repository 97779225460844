export const STEP_SELECT = 'select';
export const STEP_PHONE = 'phone';
export const STEP_CONFIRM = 'confirm';
export const STEP_RESULT = 'result';
export const STEP_CALL = 'call';

export const STEPS_IGNORE_GREETING = [STEP_CALL];

export const FORMAT_DATE = 'YYYY-MM-DD';
export const FORMAT_TIME = 'HH:mm:ss';
export const FORMAT_DATETIME = `${FORMAT_DATE} ${FORMAT_TIME}`;

// gaps when user cant place an order
export const AVAILABILITY_GAPS = [
  { from: '2019-12-30 22:00:00', till: '2020-01-03 10:59:59' },
  { from: '2020-01-12 00:00:00', till: '2020-01-17 23:59:59' }
];

// future planning period in days
export const DAYS_HORIZON = 5;

// bookings available time slots
export const AVAILABLE_DAY_FROM = '10:00:00';
export const AVAILABLE_DAY_TILL = '21:29:59';

// working time of call center
export const WORKING_DAY_FROM = '10:00:00';
export const WORKING_DAY_TILL = '21:59:59';

// limit to show right now label in seconds
export const RIGHT_NOW_THRESHOLD = 1 * 60 * 60;

// delay before first booking from current time
export const BOOKING_AVAILABILITY_DELAY = 1 * 60 * 60;

export const INTERVAL_TO_RESEND_CONFIRMATION_CODE = 60000;

// current timezone offset for all our flats
export const LOCATION_TIMEZONE_UTC_OFFSET = 60 * 3;
