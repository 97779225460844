import { Picture } from 'pik-arenda-ui-kit';
import {
  SentryHelper,
  MetricHelper,
  IdentityHelper,
  ComagicHelper,
  EventHelper,
  DebugHelper
} from 'pik-arenda-ui-kit/core';
import moment from 'moment';
import {
  MOMENT_LOCALE,
  BASE_URI_OPTIMIZER_API,
  SENTRY_DSN,
  SENTRY_ENABLED,
  METRIC_ENABLED,
  IDENTITY_ENABLED,
  COMAGIC_ENABLED,
  EVENTS_ENABLED
} from './constants';

export default function configure() {
  // configure the debug center
  DebugHelper.configure({
    prefix: 'flats-showcase',
    properties: {
      'force-record-through-form': {
        label: 'Force record thought visit form with available slots'
      }
    }
  });

  if (SENTRY_ENABLED) {
    SentryHelper.configure({ dsn: SENTRY_DSN });
    SentryHelper.enable();
  }

  if (METRIC_ENABLED) {
    MetricHelper.enable();
  }

  if (IDENTITY_ENABLED) {
    IdentityHelper.enable();
  }

  if (COMAGIC_ENABLED) {
    ComagicHelper.enable();
  }

  if (EVENTS_ENABLED) {
    EventHelper.configure({ eventCategory: 'flats' });
    EventHelper.enable();
  }

  // configure moment locale
  moment.locale(MOMENT_LOCALE);

  // configure optimization uris
  // TODO Migrate to core helper component.
  Picture.configure({
    baseUri: BASE_URI_OPTIMIZER_API
  });
}
