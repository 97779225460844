<script>
  import { HeaderWithMenuDefault } from 'pik-arenda-ui-kit';

  export let isModalVisible = false;
  export let mode = 'wide';
  export let cityDetectorEnabled = false;

  const modalWithBtnText = 'Сдать квартиру';
  const modalWithBtnMobileText = 'Сдать';
</script>

<HeaderWithMenuDefault
  withShadow
  cabinetEnabled
  bind:isModalVisible
  modalWithBtnEnabled
  {modalWithBtnText}
  {modalWithBtnMobileText}
  {mode}
  {cityDetectorEnabled} />
