<script>
  import { onMount, onDestroy } from 'svelte';
  import { InputCode, Button, Grid } from 'pik-arenda-ui-kit';
  import { SentryHelper, SingleSignHelper } from 'pik-arenda-ui-kit/core';
  import resolveAssignedBooking from './../../helpers/resolveAssignedBooking';
  import { sendBooking, fetchBookings } from '../../instances/interface';
  import { dateStore, timeStore, stepStore, phoneStore, bookingStore } from '../../stores';
  import { STEP_RESULT, STEP_SELECT } from '../../constants';

  export let id;
  export let mode;

  mode = 'default';

  let input;
  let value = '';
  let error = undefined;
  let valid = undefined;
  let countdown;
  let loading = false;
  let interval;
  let windowWidth;

  onMount(() => {
    SingleSignHelper.startCountdown();
    SingleSignHelper.bindCountdown(onTick);
  });

  onDestroy(() => {
    interval && clearInterval(interval);
  });

  async function onTick(value) {
    countdown = value;
  }

  async function onResend() {
    loading = true;
    const requestConfig = { params: { silent: true } };
    await SingleSignHelper.login($phoneStore, requestConfig);
    SingleSignHelper.restartCountdown();
    SingleSignHelper.bindCountdown(onTick);
    loading = false;
  }

  async function onSubmit(e) {
    e.preventDefault();
    const code = input.get();

    valid = input.validate();
    if (!valid) {
      return false;
    }

    loading = true;

    try {
      // retrieve token by passed code
      const requestConfig = { params: { silent: true } };
      await SingleSignHelper.confirm($phoneStore, code, requestConfig);
    } catch (exception) {
      SentryHelper.capture(exception);

      error = 'Некорректный код подтверждения';
      loading = false;
      // eslint-disable-next-line require-atomic-updates
      valid = false;
      return false;
    }

    try {
      // check for already existed booking
      const bookings = await fetchBookings(id, SingleSignHelper.token);
      const booking = resolveAssignedBooking(bookings);
      bookingStore.set(booking);
      if (booking) {
        dateStore.set(booking.date);
        timeStore.set(booking.time);
        stepStore.set(STEP_RESULT);
        return;
      }

      // confirm booking
      const result = await sendBooking(id, SingleSignHelper.token, $dateStore, $timeStore);
      // eslint-disable-next-line require-atomic-updates
      bookingStore.set(result);

      if (result.code === 403) {
        stepStore.set(STEP_SELECT);
      } else {
        stepStore.set(STEP_RESULT);
      }
    } catch (exception) {
      SentryHelper.capture(exception);

      error = 'Неизвестная ошибка: пожалуйста, попробуйте позже';
      loading = false;
      // eslint-disable-next-line require-atomic-updates
      valid = false;
      return false;
    }

    loading = false;
    return true;
  }
</script>

<style src="./styles.pcss" lang="pcss">.component{margin-top:16px}.field :global(label){margin-bottom:9px!important}.controls{margin-top:9px}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9GbGF0Qm9va2luZ0ZlYXR1cmUvc3RlcHMvRmxhdEJvb2tpbmdDb25maXJtU3RlcC9GbGF0Qm9va2luZ0NvbmZpcm1TdGVwLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxXQUNFLGVBQ0YsQ0FHRSxzQkFDRSwyQkFDRixDQUdGLFVBQ0UsY0FDRiIsImZpbGUiOiJzcmMvZmVhdHVyZXMvRmxhdEJvb2tpbmdGZWF0dXJlL3N0ZXBzL0ZsYXRCb29raW5nQ29uZmlybVN0ZXAvRmxhdEJvb2tpbmdDb25maXJtU3RlcC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyIuY29tcG9uZW50IHtcbiAgbWFyZ2luLXRvcDogMTZweDtcbn1cblxuLmZpZWxkIHtcbiAgOmdsb2JhbChsYWJlbCkge1xuICAgIG1hcmdpbi1ib3R0b206IDlweCAhaW1wb3J0YW50O1xuICB9XG59XG5cbi5jb250cm9scyB7XG4gIG1hcmdpbi10b3A6IDlweDtcbn1cbiJdfQ== */</style>

<svelte:window bind:innerWidth={windowWidth} />

<form on:submit={onSubmit} class="component" novalidate>
  <div class="field">
    <InputCode
      length={SingleSignHelper.codeLength}
      theme="regular"
      label="Код из смс"
      rules={['required', 'completed']}
      bind:this={input}
      bind:value
      {valid}
      showErrorMessage={!!error}
      errorMessage={error}
      autocomplete={false} />
  </div>

  <div class="controls">
    {#if value.length >= SingleSignHelper.codeLength}
      {#if windowWidth >= Grid.VIEWPORT_THRESHOLD_SM && windowWidth <= Grid.VIEWPORT_THRESHOLD_SM}
        <Button type="submit" block {loading} size="m">Записаться на просмотр</Button>
      {:else}
        <Button type="submit" block {loading}>Записаться на просмотр</Button>
      {/if}
    {:else if windowWidth >= Grid.VIEWPORT_THRESHOLD_SM && windowWidth <= Grid.VIEWPORT_THRESHOLD_MD}
      <Button block disabled={countdown > 0} on:click={onResend} {loading} size="m">
        {#if countdown > 0}Еще раз через {countdown} сек.{:else}Отправить код еще раз{/if}
      </Button>
    {:else}
      <Button block disabled={countdown > 0} on:click={onResend} {loading}>
        {#if countdown > 0}Еще раз через {countdown} сек.{:else}Отправить код еще раз{/if}
      </Button>
    {/if}
  </div>
</form>
