<script>
  import { links } from 'svelte-routing';
  import { createEventDispatcher } from 'svelte';
  import { FlatCard, Button, Spin } from 'pik-arenda-ui-kit';
  import createUriWithBase from './../../helpers/createUriWithBase';
  import { URI_DETAIL } from './../../constants';

  const dispatch = createEventDispatcher();

  export let data = [];
  export let page = 0;
  export let height = '100%';
  export let more = false;
  export let loading = false;
  export let allowEmpty = true;

  function onClick(item) {
    dispatch('visit', { item });
  }

  function onHoverIn(item) {
    dispatch('hover-in', { item });
  }

  function onHoverOut(item) {
    dispatch('hover-out', { item });
  }

  function onShowMoreClick(e) {
    e.preventDefault();
    dispatch('more');
  }
</script>

<style src="./styles.pcss" lang="pcss">.wrapper{display:inline-block;margin:0 auto;width:100%}@media (min-width:768px){.wrapper{margin:0 10px 0 0;padding-right:17px;padding-left:10px;width:312px}}@media (min-width:1200px){.wrapper{width:409px}}.link{text-decoration:none}.flatcard{margin-bottom:12px}@media (min-width:768px){.flatcard{margin-bottom:20px}}.flatcard:hover :global(.flatcard){box-shadow:0 4px 8px rgba(0,0,0,.25)}.placeholder{height:100%;text-align:center;flex-direction:column;display:flex;align-items:center;justify-content:center}.suffix{position:relative;height:100px}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9GbGF0c1NlYXJjaExpc3RGZWF0dXJlL0ZsYXRzU2VhcmNoTGlzdEZlYXR1cmUuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFNBQ0Usb0JBQXFCLENBQ3JCLGFBQWMsQ0FDZCxVQVlGLENBVkUseUJBTEYsU0FNSSxpQkFBa0IsQ0FDbEIsa0JBQW1CLENBQ25CLGlCQUFrQixDQUNsQixXQU1KLENBTEUsQ0FFQSwwQkFaRixTQWFJLFdBRUosQ0FERSxDQUdGLE1BQ0Usb0JBQ0YsQ0FFQSxVQUNFLGtCQVNGLENBUkUseUJBRkYsVUFHSSxrQkFPSixDQU5FLENBRUUsbUNBQ0Usb0NBQ0YsQ0FJSixhQUNFLFdBQVksQ0FDWixpQkFBa0IsQ0FDbEIscUJBQXNCLENBQ3RCLFlBQWEsQ0FDYixrQkFBbUIsQ0FDbkIsc0JBQ0YsQ0FFQSxRQUNFLGlCQUFrQixDQUNsQixZQUNGIiwiZmlsZSI6InNyYy9mZWF0dXJlcy9GbGF0c1NlYXJjaExpc3RGZWF0dXJlL0ZsYXRzU2VhcmNoTGlzdEZlYXR1cmUuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLndyYXBwZXIge1xuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gIG1hcmdpbjogMCBhdXRvO1xuICB3aWR0aDogMTAwJTtcblxuICBAbWVkaWEgKC0tbWQpIHtcbiAgICBtYXJnaW46IDAgMTBweCAwIDA7XG4gICAgcGFkZGluZy1yaWdodDogMTdweDtcbiAgICBwYWRkaW5nLWxlZnQ6IDEwcHg7XG4gICAgd2lkdGg6IDMxMnB4O1xuICB9XG5cbiAgQG1lZGlhICgtLWxnKSB7XG4gICAgd2lkdGg6IDQwOXB4O1xuICB9XG59XG5cbi5saW5rIHtcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xufVxuXG4uZmxhdGNhcmQge1xuICBtYXJnaW4tYm90dG9tOiAxMnB4O1xuICBAbWVkaWEgKC0tbWQpIHtcbiAgICBtYXJnaW4tYm90dG9tOiAyMHB4O1xuICB9XG4gICY6aG92ZXIge1xuICAgIDpnbG9iYWwoLmZsYXRjYXJkKSB7XG4gICAgICBib3gtc2hhZG93OiAwIDRweCA4cHggcmdiYSgwLCAwLCAwLCAwLjI1KTtcbiAgICB9XG4gIH1cbn1cblxuLnBsYWNlaG9sZGVyIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuXG4uc3VmZml4IHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBoZWlnaHQ6IDEwMHB4O1xufVxuIl19 */</style>

<div class="wrapper" style="height: {height};">
  {#if data.length}
    <ul class="flatlist" use:links>
      {#each data as item (item.id)}
        <li class="flatcard">
          <a
            class="link"
            href={createUriWithBase(URI_DETAIL, { id: item.id })}
            on:click={onClick.bind(undefined, item)}
            on:mouseenter={onHoverIn.bind(undefined, item)}
            on:mouseleave={onHoverOut.bind(undefined, item)}
            target="_blank"
            noroute>
            <FlatCard
              flatRooms={item.flatRooms}
              flatSquare={item.flatSquare}
              flatPrice={item.flatPrice}
              flatGallery={item.flatGallery}
              flatHasDeposit={item.flatDeposit}
              flatHasCommission={item.flatCommission}
              flatFloor={item.flatFloor}
              flatSelected={item.flatSelected}
              flatVisited={item.flatVisited}
              houseFloorsCount={item.houseFloorsCount}
              houseStationLabel={item.houseStationLabel}
              houseStationColor={item.houseStationColor}
              houseStationWalk={item.houseStationWalk}
              houseStationDrive={item.houseStationDrive}
              bookButtonVisible />
          </a>
        </li>
      {/each}
    </ul>

    <div class="suffix">
      {#if more}
        <Spin mode="fill" size="l" spinning={loading} />
      {/if}
    </div>
  {:else if allowEmpty}
    <div class="placeholder">
      К сожалению,
      <br />
      в этой области карты
      <br />
      ничего не найдено.
      <br />
      <br />
      <Button on:click={onShowMoreClick}>Показать больше</Button>
    </div>
  {/if}
</div>
