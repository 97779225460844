import 'regenerator-runtime/runtime';

// import polyfills
import 'pik-arenda-ui-kit/polyfills';

// import global styles from the ui kit
import 'pik-arenda-ui-kit/src/styles/global.pcss';

// import core dependencies
import { renderClient } from 'svelte-ssr/client';
import configure from './configure';
import component from './App.svelte';

// configure the environment initially
configure();

// render application inside the target
renderClient({ component, target: '#root' });
