<script>
  import { Phone } from 'pik-arenda-ui-kit';

  export let id;
  export let flat;
  export let mode;

  mode = 'call';

  $: phoneStore = flat.bookingPhone;
</script>

<style src="./styles.pcss" lang="pcss">.call{margin-top:18px}@media (min-width:768px){.call{margin-top:24px}}.phone :global([data-phone]){display:block;font-weight:600;font-size:18px;line-height:24px;color:#333;text-decoration:none}.description{margin-top:4px}.description :global([data-phone]){display:block;font-weight:500;font-size:12px;line-height:14px;color:#5e6366;text-decoration:none}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9GbGF0Qm9va2luZ0ZlYXR1cmUvc3RlcHMvRmxhdEJvb2tpbmdDYWxsU3RlcC9GbGF0Qm9va2luZ0NhbGxTdGVwLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSxNQUNFLGVBS0YsQ0FIRSx5QkFIRixNQUlJLGVBRUosQ0FERSxDQUlBLDZCQUNFLGFBQWMsQ0FDZCxlQUFnQixDQUNoQixjQUFlLENBQ2YsZ0JBQWlCLENBQ2pCLFVBQWEsQ0FDYixvQkFDRixDQUdGLGFBQ0UsY0FVRixDQVJFLG1DQUNFLGFBQWMsQ0FDZCxlQUFnQixDQUNoQixjQUFlLENBQ2YsZ0JBQWlCLENBQ2pCLGFBQWlCLENBQ2pCLG9CQUNGIiwiZmlsZSI6InNyYy9mZWF0dXJlcy9GbGF0Qm9va2luZ0ZlYXR1cmUvc3RlcHMvRmxhdEJvb2tpbmdDYWxsU3RlcC9GbGF0Qm9va2luZ0NhbGxTdGVwLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIi5jYWxsIHtcbiAgbWFyZ2luLXRvcDogMThweDtcblxuICBAbWVkaWEgKC0tbWQpIHtcbiAgICBtYXJnaW4tdG9wOiAyNHB4O1xuICB9XG59XG5cbi5waG9uZSB7XG4gIDpnbG9iYWwoW2RhdGEtcGhvbmVdKSB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgICBmb250LXNpemU6IDE4cHg7XG4gICAgbGluZS1oZWlnaHQ6IDI0cHg7XG4gICAgY29sb3I6ICRibGFjaztcbiAgICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gIH1cbn1cblxuLmRlc2NyaXB0aW9uIHtcbiAgbWFyZ2luLXRvcDogNHB4O1xuXG4gIDpnbG9iYWwoW2RhdGEtcGhvbmVdKSB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgZm9udC13ZWlnaHQ6IDUwMDtcbiAgICBmb250LXNpemU6IDEycHg7XG4gICAgbGluZS1oZWlnaHQ6IDE0cHg7XG4gICAgY29sb3I6ICRncmF5LWRhcms7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiBub25lO1xuICB9XG59XG4iXX0= */</style>

<div class="call">
  <div class="phone">
    <Phone value={phoneStore} dynamic={false} />
  </div>

  <div class="description">
    <Phone value={phoneStore} dynamic={false}>Запишитесь на просмотр по телефону</Phone>
  </div>
</div>
