<script>
  import { Button } from 'pik-arenda-ui-kit';

  export let booked = false;
  export let loading = false;
  export let disabled = false;
</script>

<style src="./styles.pcss">.call-to-action{display:flex;flex-flow:column nowrap;padding:22px;background-color:#f7f9fa;justify-content:flex-start;border-radius:5px;line-height:36px}@media (min-width:768px){.call-to-action{padding:14px 24px}}@media (min-width:1200px){.call-to-action{padding:22px;flex-flow:row nowrap;align-items:center}}.btn{text-align:center}@media (min-width:768px){.btn{text-align:left}}.title{display:none;font-size:30px;font-weight:700;color:#0a055f;margin-bottom:16px}@media (min-width:768px){.title{display:inline-block}}@media (min-width:1200px){.title{margin-right:40px;margin-bottom:0}}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9GbGF0RGV0YWlsQ2FsbFRvQWN0aW9uRmVhdHVyZS9GbGF0RGV0YWlsQ2FsbFRvQWN0aW9uRmVhdHVyZS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsZ0JBQ0UsWUFBYSxDQUNiLHVCQUF3QixDQUN4QixZQUFhLENBQ2Isd0JBQThCLENBQzlCLDBCQUEyQixDQUMzQixpQkFBa0IsQ0FDbEIsZ0JBVUYsQ0FSRSx5QkFURixnQkFVSSxpQkFPSixDQU5FLENBQ0EsMEJBWkYsZ0JBYUksWUFBYSxDQUNiLG9CQUFxQixDQUNyQixrQkFFSixDQURFLENBR0YsS0FDRSxpQkFJRixDQUhFLHlCQUZGLEtBR0ksZUFFSixDQURFLENBRUYsT0FDRSxZQUFhLENBQ2IsY0FBZSxDQUNmLGVBQWlCLENBQ2pCLGFBQWdCLENBQ2hCLGtCQVVGLENBUkUseUJBUEYsT0FRSSxvQkFPSixDQU5FLENBRUEsMEJBWEYsT0FZSSxpQkFBa0IsQ0FDbEIsZUFFSixDQURFIiwiZmlsZSI6InNyYy9mZWF0dXJlcy9GbGF0RGV0YWlsQ2FsbFRvQWN0aW9uRmVhdHVyZS9GbGF0RGV0YWlsQ2FsbFRvQWN0aW9uRmVhdHVyZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyIuY2FsbC10by1hY3Rpb24ge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWZsb3c6IGNvbHVtbiBub3dyYXA7XG4gIHBhZGRpbmc6IDIycHg7XG4gIGJhY2tncm91bmQtY29sb3I6ICRjbG91ZHktZ3JheTtcbiAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0O1xuICBib3JkZXItcmFkaXVzOiA1cHg7XG4gIGxpbmUtaGVpZ2h0OiAzNnB4O1xuXG4gIEBtZWRpYSAoLS1tZCkge1xuICAgIHBhZGRpbmc6IDE0cHggMjRweDtcbiAgfVxuICBAbWVkaWEgKC0tbGcpIHtcbiAgICBwYWRkaW5nOiAyMnB4O1xuICAgIGZsZXgtZmxvdzogcm93IG5vd3JhcDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICB9XG59XG5cbi5idG4ge1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIEBtZWRpYSAoLS1tZCkge1xuICAgIHRleHQtYWxpZ246IGxlZnQ7XG4gIH1cbn1cbi50aXRsZSB7XG4gIGRpc3BsYXk6IG5vbmU7XG4gIGZvbnQtc2l6ZTogMzBweDtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIGNvbG9yOiAkc2FwcGhpcmU7XG4gIG1hcmdpbi1ib3R0b206IDE2cHg7XG5cbiAgQG1lZGlhICgtLW1kKSB7XG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICB9XG5cbiAgQG1lZGlhICgtLWxnKSB7XG4gICAgbWFyZ2luLXJpZ2h0OiA0MHB4O1xuICAgIG1hcmdpbi1ib3R0b206IDA7XG4gIH1cbn1cbiJdfQ== */</style>

<div class="call-to-action">
  <h2 class="title">Понравилась квартира?</h2>
  <div class="btn">
    <Button {loading} {disabled} on:click>
      {#if booked}Вы записались на просмотр{:else}Записаться на просмотр{/if}
    </Button>
  </div>
</div>
