import moment from 'moment';
import { AVAILABILITY_GAPS, FORMAT_DATETIME } from '../constants';
import { utcStore } from '../stores';

let utcOffset;

utcStore.subscribe(value => {
  utcOffset = value;
});

export default function filterSlotsByAvailabilityGaps(raw) {
  const gaps = AVAILABILITY_GAPS.map(gap => ({
    from: moment(gap.from, FORMAT_DATETIME).utcOffset(utcOffset, true),
    till: moment(gap.till, FORMAT_DATETIME).utcOffset(utcOffset, true)
  }));

  return raw.filter(datetime => {
    const timestamp = moment.parseZone(datetime);

    // filter working gaps
    for (const gap of gaps) {
      if (timestamp.isBetween(gap.from, gap.till, 'minute', '[]')) {
        return false;
      }
    }

    return true;
  });
}
