import DetailPage from './pages/DetailPage';
import SearchPage from './pages/SearchPage';
import { SystemDebugger } from 'pik-arenda-ui-kit';
import { URI_SEARCH, URI_DETAIL } from './constants';

export default [
  { path: URI_SEARCH, component: SearchPage, exact: true },
  { path: URI_DETAIL, component: DetailPage, exact: true },
  { path: SystemDebugger.ROUTE, component: SystemDebugger, exact: true }
];
