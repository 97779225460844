import moment from 'moment';
import { AVAILABLE_DAY_FROM, AVAILABLE_DAY_TILL, FORMAT_TIME } from '../constants';
import { utcStore } from '../stores';

let utcOffset;

utcStore.subscribe(value => {
  utcOffset = value;
});

export default function filterSlotsByWorkingPeriod(raw) {
  const thresholdFrom = moment(AVAILABLE_DAY_FROM, FORMAT_TIME).utcOffset(utcOffset, true);
  const thresholdTill = moment(AVAILABLE_DAY_TILL, FORMAT_TIME).utcOffset(utcOffset, true);
  return raw.filter(datetime => {
    const timestamp = moment.parseZone(datetime);

    // set date time offset regarding current timestamp
    thresholdFrom
      .year(timestamp.year())
      .month(timestamp.month())
      .date(timestamp.date());

    thresholdTill
      .year(timestamp.year())
      .month(timestamp.month())
      .date(timestamp.date());

    // if current timestamp between filters
    return timestamp.isBetween(thresholdFrom, thresholdTill, 'minute', '[]');
  });
}
