import moment from 'moment';
import { FORMAT_DATE, FORMAT_TIME } from '../constants';

export default function resolveSlotsHierarchy(raw) {
  const tree = raw.reduce((stack, datetime) => {
    const timestamp = moment.parseZone(datetime);
    const date = timestamp.format(FORMAT_DATE);
    const time = timestamp.format(FORMAT_TIME);

    stack[date] = stack[date] || { date: date, times: [] };
    stack[date].times.push(time);
    return stack;
  }, {});

  return Object.values(tree);
}
