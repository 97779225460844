import { SEARCH_QUERY_PARAM_PRICE_FROM, SEARCH_QUERY_PARAM_PRICE_TO, SEARCH_QUERY_PARAM_ROOMS } from '../constants';

export default function mapQueryToApiQuery(query) {
  return {
    page: query.page || 0,
    price_min: query[SEARCH_QUERY_PARAM_PRICE_FROM] || undefined,
    price_max: query[SEARCH_QUERY_PARAM_PRICE_TO] || undefined,
    rooms: query[SEARCH_QUERY_PARAM_ROOMS] || undefined,
    coords: query.bounds
      ? [query.bounds[0].lat, query.bounds[0].lng, query.bounds[1].lat, query.bounds[1].lng]
      : undefined
  };
}
