export default function isBookingOutdated(booking) {
  const bookingDateAndTime = new Date(`${booking.date}T${booking.time}`);
  const now = new Date();

  if (bookingDateAndTime > now) {
    return false;
  }

  return true;
}
