import moment from 'moment';
import { FORMAT_DATETIME, RIGHT_NOW_THRESHOLD } from '../constants';
import { utcStore } from '../stores';

let utcOffset;

utcStore.subscribe(value => {
  utcOffset = value;
});

export default function isAvailableToSeeRightNow(slots) {
  if (!slots[0] || !slots[0].times[0]) {
    return false;
  }

  const now = moment().utcOffset(utcOffset);
  const then = moment(`${slots[0].date} ${slots[0].times[0]}`, FORMAT_DATETIME).utcOffset(utcOffset, true);

  return now.unix() + RIGHT_NOW_THRESHOLD >= then.unix();
}
