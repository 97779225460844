import moment from 'moment';
import { FORMAT_TIME } from '../constants';

export default function resolveTimesOptions(slots, date = false) {
  if (!date) {
    return [];
  }

  const group = slots.find(slot => slot.date === date);
  if (!group) {
    return [];
  }

  return group.times.map(time => ({
    value: time,
    label: moment(time, FORMAT_TIME).format('HH:mm')
  }));
}
