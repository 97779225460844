<script>
  import { FlatMap } from 'pik-arenda-ui-kit';

  export let map;
  export let data = [];
  export let center;
  export let zoom;
  export let height = '';
  export let width = '';
  export let loading = false;
  export let isMapScrollZoomDisabled = true;

  function handleLoaded() {
    handleMapZoom();
  }

  function handleMapZoom() {
    if (!map) {
      return;
    }

    if (isMapScrollZoomDisabled) {
      map.instance.behaviors.disable('scrollZoom');
    } else {
      map.instance.behaviors.enable('scrollZoom');
    }
  }

  $: handleMapZoom(isMapScrollZoomDisabled);
</script>

<style src="./styles.pcss" lang="pcss">
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJzcmMvZmVhdHVyZXMvRmxhdHNTZWFyY2hNYXBGZWF0dXJlL0ZsYXRzU2VhcmNoTWFwRmVhdHVyZS5zdmVsdGUifQ== */</style>

<div class="component">
  <FlatMap
    bind:map
    points={data}
    {center}
    {zoom}
    {height}
    {width}
    on:select
    on:clickMap
    on:changeBounds
    on:changeCenter
    on:changeZoom
    on:geoposition
    on:loaded={handleLoaded} />
</div>
