import moment from 'moment';
import { FORMAT_DATE, FORMAT_TIME } from '../constants';

export default function resolveAssignedBooking(data) {
  if (!data || !data.length) {
    return false;
  }

  // sort assigned bookings
  const assigned = [...data];
  assigned.sort((a, b) => {
    const left = moment(a.time_from).unix();
    const right = moment(b.time_from).unix();
    if (left > right) {
      return -1;
    } else if (left < right) {
      return 1;
    }

    return 0;
  });

  // get newest booking from the list
  const newest = assigned[0];
  const id = parseInt(newest.deal_id, 10);
  const timestamp = moment(newest.time_from);
  const date = timestamp.format(FORMAT_DATE);
  const time = timestamp.format(FORMAT_TIME);
  return { id, date, time };
}
