import moment from 'moment';
import { DAYS_HORIZON } from '../constants';
import { utcStore } from '../stores';

export default function filterSlotsByHorizonPeriod(raw) {
  const dateFrom = new Date();
  const dateTo = new Date();

  (dateFrom.getMinutes > 0 && dateFrom.getMinutes < 30) || (dateFrom.getMinutes >= 30 && dateFrom.getMinutes <= 59)
    ? dateFrom.setMinutes(30)
    : dateFrom.setMinutes(0);

  dateFrom.setSeconds(0);

  dateTo.setDate(dateTo.getDate() + DAYS_HORIZON);
  dateTo.setHours(23);
  dateTo.setMinutes(30);
  dateTo.setSeconds(0);

  return raw.filter(datetime => {
    // filter that slot in users period
    const timestamp = moment.parseZone(datetime);
    const utcOffset = timestamp.utcOffset();
    const from = moment(dateFrom).utcOffset(utcOffset);
    const to = moment(dateTo).utcOffset(utcOffset);

    utcStore.set(utcOffset);

    return timestamp.isBetween(from, to, 'minute', '()');
  });
}
